import { useEffect, useState } from "react";

function useWindowSize() {
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    // Update the width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    isMobile: windowWidth <= 1279,
    isDesktop: windowWidth > 1279,
  };
}

export default useWindowSize;
