export default function slideCalculator(slides1: any[], slides2: any[]) {
  if (!slides1?.length && !slides2?.length)
    return { rows: 0, arrayToRender: [] };

  const total = slides1.length + slides2.length;
  const rows = Math.ceil(total / 4);

  let arrayToRender: any = [];

  for (let i = 0; i < rows; i++) {
    const tempSlidesArr: any = [];
    tempSlidesArr.push(slides1[2 * i]);
    tempSlidesArr.push(slides1[2 * i + 1]);
    tempSlidesArr.push(slides2[2 * i]);
    tempSlidesArr.push(slides2[2 * i + 1]);

    arrayToRender.push(tempSlidesArr);
  }

  return { rows, arrayToRender };
}

export function singleSlideCalculator(slides: any[]) {
  if (!slides?.length) return { rows: 0, arrayToRender: [] };

  const total = slides.length;
  const rows = Math.ceil(total / 4);

  let arrayToRender: any = [];

  for (let i = 0; i < rows; i++) {
    const tempSlidesArr: any = [];
    tempSlidesArr.push(slides[2 * i]);
    tempSlidesArr.push(slides[2 * i + 1]);
    tempSlidesArr.push(slides[2 * i + 2]);
    tempSlidesArr.push(slides[2 * i + 3]);

    arrayToRender.push(tempSlidesArr);
  }

  return { rows, arrayToRender };
}
