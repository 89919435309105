"use client"

import { ModalContext } from "@/context/ModalContext"
import { IModalActions } from "@/types/modal"
import { useContext } from "react"

export const ModalButton = ({
  action,
  data,
  className,
  children,
  ariaLableText='play',
  handleClickCB = () => {}
}: {
  action: IModalActions
  data: any
  className?: any
  children?: React.ReactNode
  ariaLableText?:string
  handleClickCB?: any
}) => {
  const { modalDispatch } = useContext(ModalContext)
  const handleClick = () => {
    handleClickCB && handleClickCB();
    modalDispatch({
      type: action,
      data,
    })
  }
  if (!children) {
    return <button onClick={handleClick} className={className} />
  }

  return (
    <button onClick={handleClick} className={className} aria-label={ariaLableText}>
      {children}
    </button>
  )
}
